import * as Noodl from "@noodl/noodl-sdk";
import * as ContextNode from "./reactNodes/ContextNode";
import * as ClearContextNode from "./nodes/ClearContext";
import * as EventNode from "./nodes/Event";
import * as NotifyNode from "./nodes/Notify";
import * as SetContextNode from "./nodes/SetContext";
Noodl.defineModule({
    reactNodes: [
        ContextNode.node,
    ],
    nodes: [
        ClearContextNode.node,
        EventNode.node,
        NotifyNode.node,
        SetContextNode.node,
    ],
    setup() { },
});
