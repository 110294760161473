import { honeybadger } from "../reactNodes/ContextNode";
const nodeName = "honeybadger.notify";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Honeybadger Notify",
    category: "Logic",
    inputs: {
        error: {
            group: "General",
            type: "string",
        },
    },
    signals: {
        Do() {
            honeybadger.notify(this.inputs.error);
        }
    }
});
