import { honeybadger } from "../reactNodes/ContextNode";
const nodeName = "honeybadger.set-context";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Honeybadger Set Context",
    category: "Logic",
    inputs: {
        context: {
            group: "General",
            type: "object",
        },
    },
    signals: {
        Do() {
            honeybadger.setContext(this.inputs.context);
        }
    }
});
