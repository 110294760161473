import { honeybadger } from "../reactNodes/ContextNode";
const nodeName = "honeybadger.event";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Honeybadger Event",
    category: "Logic",
    inputs: {
        type: {
            group: "General",
            type: "string",
        },
        data: {
            group: "General",
            type: "object",
        },
    },
    signals: {
        Do() {
            if (this.inputs.type) {
                honeybadger.event(this.inputs.type, this.inputs.data);
            }
            else {
                honeybadger.event(this.inputs.data);
            }
        }
    }
});
