import { jsx as _jsx } from "react/jsx-runtime";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import { useMemo } from "react";
export let honeybadger = null;
function NodeContext({ apiKey, environment, revision, children }) {
    const hb = useMemo(() => {
        return honeybadger = Honeybadger.configure({
            apiKey,
            environment,
            revision,
        });
    }, [apiKey, environment, revision]);
    return (_jsx(HoneybadgerErrorBoundary, Object.assign({ honeybadger: hb }, { children: children })));
}
const nodeName = "honeybadger.context";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Honeybadger Context",
    category: "Visual",
    getReactComponent() {
        return NodeContext;
    },
    inputProps: {
        apiKey: {
            group: "Setup",
            type: "string",
            displayName: "API Key",
        },
        environment: {
            group: "Setup",
            type: "string",
            displayName: "Environment",
        },
        revision: {
            group: "Setup",
            type: "string",
            displayName: "Revision",
        },
    }
});
