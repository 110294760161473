import { honeybadger } from "../reactNodes/ContextNode";
const nodeName = "honeybadger.clear-context";
export const node = Noodl.defineNode({
    name: nodeName,
    displayName: "Honeybadger Clear Context",
    category: "Logic",
    signals: {
        Do() {
            honeybadger.clear();
        }
    }
});
